import React from 'react'
import { dayGetter } from '../../utils/date.utils'
import RadialGauge from '../RadialGauge/RadialGauge'
import { styled } from '@mui/material'
import Stack from '@mui/material/Stack'

const DetailHeader = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '0.3rem'
}))

const HeaderItem = styled('div')(() => ({
  display: 'flex'
}))

const HeaderValue = styled('h4')(() => ({
  display: 'flex',
  margin: '0',
  width: '100%',
  marginLeft: '1rem'
}))

const ParameterSpan = styled('span')(() => ({
  color: 'grey',
  marginLeft: '5px'
}))

const ParameterSpan2 = styled('span')(() => ({
  marginLeft: 'auto'
}))

const Details = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flex: '1 1 auto'
}))

const ParametersContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '5px 15px'
}))

const ParameterItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '1rem'
}))

const ProgressItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '1rem'
}))

const ProgressLabel = styled('div')(() => ({
  width: '30%',
  justifySelf: 'flex-end',

  h4: {
    margin: 0
  }
}))

const ProgressValue = styled('div')(() => ({
  width: '70%'
}))

const ParameterValue = styled('div')(() => ({
  color: 'grey',
  margin: 0
}))

const ProgressBarContainer = styled('div')(() => ({
  backgroundColor: 'rgb(192, 192, 192)',
  width: '100%',
  height: '10px',
  borderRadius: '5px'
}))

const ProgressBar = styled('div')(() => ({
  // backgroundColor: 'rgb(116, 194, 92)',
  color: 'white',
  // padding: '1%',
  height: '100%',
  textAlign: 'right',
  fontSize: '20px',
  borderRadius: '5px'
}))

const GaugeContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '25%'
}))

const SmallText = styled('p')(() => ({
  fontWeight:'bold',
  marginTop: 0,
  marginBottom: 0
}))

const OperatorDetails = ({ operatorData }) => {
  const { daysInMonth, monthOfReport } = dayGetter(operatorData[0]['date'])

  const getData = (operatorDataArray) => {
    const unique = []
    const uniqueStringArray = []
    let count = 0
    let totalTarget = 0
    operatorDataArray.forEach((item) => {
      const uniqueString = `${item['date']}-${item['machine']}-${item['shift']}-${item['productionId']}`
      if (!unique.includes(item['date'])) {
        unique.push(item['date'])
        count += 1
      }
      if (!uniqueStringArray.includes(uniqueString)) {
        uniqueStringArray.push(uniqueString)
        totalTarget += Number(item['target'])
      }
    })
    return { count, totalTarget }
  }

  const { count: noOfDaysWorked, totalTarget } = getData(operatorData)

  const totalAchieved = operatorData.reduce(
    (accu, element) => accu + Number(element['achieved_qty']),
    0
  )

  const totalIdletime = operatorData.reduce(
    (accu, element) => accu + Number(element['idletime']),
    0
  )

  const totalRejection = operatorData.reduce(
    (accu, element) => accu + Number(element['rejection']),
    0
  )

  const attendanceRate = parseInt((noOfDaysWorked / daysInMonth) * 100)
  const achievedRate = parseInt((totalAchieved / totalTarget) * 100)
  const acceptanceRate = parseInt(100 - (totalRejection / totalAchieved) * 100)
  const availabilityRate = parseInt(
    100 - (totalIdletime / (noOfDaysWorked * 620)) * 100
  )

  const indicatorValue =
    (attendanceRate + achievedRate + acceptanceRate + availabilityRate) / 4

  return (
    <div className="container flex-column" style={{ width: '98%' }}>
      <DetailHeader>
        <HeaderItem>
          <HeaderValue>
            Operator Name:
            <ParameterSpan>{operatorData[0]['operator']}</ParameterSpan>
          </HeaderValue>
        </HeaderItem>
        <HeaderItem>
          <HeaderValue>
            Operator ID:
            <ParameterSpan>{operatorData[0]['operatorId']}</ParameterSpan>
          </HeaderValue>
        </HeaderItem>
        <HeaderItem>
          <HeaderValue>
            Month of Reporting:
            <ParameterSpan>{monthOfReport}</ParameterSpan>
          </HeaderValue>
        </HeaderItem>
      </DetailHeader>
      <hr style={{ width: '98%' }} className="hr" />
      <Details>
        <ParametersContainer>
          <ProgressItem>
            <ProgressLabel>
              <h4>No of Days worked</h4>
              <ParameterValue>
                {noOfDaysWorked} / {daysInMonth}
              </ParameterValue>
            </ProgressLabel>
            <ProgressValue>
              <SmallText>
                Attendance Rate{' '}
                <span style={{ float:'right' }}>
                  {(attendanceRate > 100 ? 100 : attendanceRate) + '%'}
                </span>
              </SmallText>
              <ProgressBarContainer>
                <ProgressBar
                  className="bg-info"
                  style={{ width: attendanceRate + '%' }}
                ></ProgressBar>
              </ProgressBarContainer>
            </ProgressValue>
          </ProgressItem>
          <ProgressItem>
            <ProgressLabel>
              <h4> Achieved Vs Target </h4>
              <ParameterValue>
                {totalAchieved} / {totalTarget}
              </ParameterValue>
            </ProgressLabel>
            <ProgressValue>
              <SmallText>
                Achieved Rate{' '}
                <span style={{ float:'right' }}>{achievedRate + '%'}</span>
              </SmallText>
              <ProgressBarContainer>
                <ProgressBar
                  className="bg-warning"
                  style={{ width: Math.min(achievedRate, 100) + '%' }}
                ></ProgressBar>
              </ProgressBarContainer>
            </ProgressValue>
          </ProgressItem>
          <ProgressItem>
            <ProgressLabel>
              <h4>Total Rejection</h4>
              <ParameterValue> {totalRejection}</ParameterValue>
            </ProgressLabel>
            <ProgressValue>
              <SmallText>
                Acceptance Rate{' '}
                <span style={{ float:'right' }}>
                  {acceptanceRate + '%'}
                </span>
              </SmallText>
              <ProgressBarContainer>
                <ProgressBar
                  className="bg-danger"
                  style={{ width: acceptanceRate + '%' }}
                ></ProgressBar>
              </ProgressBarContainer>
            </ProgressValue>
          </ProgressItem>
          <ProgressItem>
            <ProgressLabel>
              <h4>Total Idletime</h4>
              <ParameterValue> {totalIdletime}</ParameterValue>
            </ProgressLabel>
            <ProgressValue>
              <SmallText>
                Availability Rate{' '}
                <span style={{ float:'right' }}>
                  {availabilityRate + '%'}
                </span>
              </SmallText>
              <ProgressBarContainer>
                <ProgressBar
                  className="bg-success"
                  style={{ width: availabilityRate + '%' }}
                ></ProgressBar>
              </ProgressBarContainer>
            </ProgressValue>
          </ProgressItem>
        </ParametersContainer>
        <GaugeContainer>
          <SmallText>Performance Indicator </SmallText>
          <RadialGauge value={indicatorValue} />
        </GaugeContainer>
      </Details>
    </div>
  )
}

export default OperatorDetails
