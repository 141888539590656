import React, { useEffect, useState } from 'react'

//service
import filterConfigService from '../../../service/filterConfig.service'

//utils
import { dark } from '@mui/material/styles/createPalette'
import { showNotification } from '../../../utils/toast.utils'
import FilterConfigCard from './FilterConfigCard'

const ConfigList = () => {
  const [configList, setConfigList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchConfig = async () => {
    try {
      setIsLoading(true)
      const response = await filterConfigService.getFilterConfigs()
      console.log(response.data.data)
      if (response.data.success) {
        setConfigList(response.data.data)
      } else {
        showNotification(response.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchConfig()
  }, [])

  const selectFilterConfig = async (id) => {
    let arrayOfIds = []
    configList.forEach((data) => {
      if (data.id !== id) {
        arrayOfIds.push(data.id)
      }
    })

    try {
      setIsLoading(true)
      const response = await filterConfigService.selectFilterConfig(
        id,
        arrayOfIds
      )
      if (response.data.success) {
        showNotification('Filter selection successful', 'success')
        fetchConfig()
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const deleteFilterConfig = async (id) => {
    try {
      setIsLoading(true)
      const response = await filterConfigService.deleteFilterConfig(id)
      if (response.data.success) {
        showNotification('Filter deleted successful', 'success')
        fetchConfig()
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '24px'
      }}
    >
      {React.Children.toArray(
        configList.map((data) => (
          <FilterConfigCard
            data={data}
            selectFilterConfig={selectFilterConfig}
            deleteFilterConfig={deleteFilterConfig}
          />
        ))
      )}
    </div>
  )
}

export default ConfigList
