import React from 'react'
import { useTable } from 'react-table'
import styled from 'styled-components'

const OperatorTable = styled('table')(() => ({
  borderCollapse: 'collapse',
  width: '100%'
}))

const OperatorTableEntry = styled('td')(() => ({
  border: '1px solid #dddddd',
  textAlign: 'centre',
  padding: '8px'
}))

const OperatorCard = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  justifyContent: 'right',
  alignItems: 'center',
  flex: 1,
  border: '1px solid #ddd',
  borderRadius: '12px'
}))

const AchieversTable = ({ achieversData, tableTitle, isAchiever }) => {
  const processedData = React.useMemo(() => {
    return achieversData
      .map((rowObj) => ({
        ...rowObj,
        percent:
          rowObj.target > 0
            ? `${parseInt((Number(rowObj.achieved_qty) / Number(rowObj.target)) * 100)}%`
            : '0%'
      }))
      .filter((item) =>
        isAchiever
          ? (Number(item.achieved_qty) / Number(item.target)) * 100 > 90
          : (Number(item.achieved_qty) / Number(item.target)) * 100 < 90
      )
  }, [achieversData, isAchiever])

  const columns = React.useMemo(
    () => [
      { Header: 'Operator ID', accessor: 'operatorId' },
      { Header: 'Operator', accessor: 'operator' },
      { Header: 'Machine', accessor: 'machine' },
      { Header: 'Target', accessor: 'target' },
      { Header: 'Achieved', accessor: 'achieved_qty' },
      { Header: 'Efficiency', accessor: 'percent' }
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: processedData })

  return (
    <OperatorCard>
      <h3 style={{ textAlign: 'left' }}>{tableTitle}</h3>
      <OperatorTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  <OperatorTableEntry
                    {...cell.getCellProps()}
                    key={cell.column.id}
                  >
                    {cell.render('Cell')}
                  </OperatorTableEntry>
                ))}
              </tr>
            )
          })}
        </tbody>
      </OperatorTable>
    </OperatorCard>
  )
}

export default AchieversTable
