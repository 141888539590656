import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'

import { useDateSelectorContext } from '../../context/DateSelectorContext'
import { showNotification } from '../../utils/toast.utils'

import liveDataService from '../../service/liveData.service'

import AchieversTable from './AchieversTable'

const OperatorTablesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 0 0 16px;
`
const Heading = styled.h3`
  padding: 16px 0 0 16px;
`

const OperatorPerformanceDayWise = () => {
  const [productionRecordRows, setProductionRecordRows] = useState([])
  const { selectedDate } = useDateSelectorContext()
  const [isLoading, setIsLoading] = useState(false)

  const fetchProductionRecords = async () => {
    const params = {}

    if (selectedDate) {
      params.date = selectedDate
    }

    try {
      setIsLoading(true)
      const productionRecordResponse =
        await liveDataService.getOperatorsDataDayWise(params)
      const data = productionRecordResponse.data.data

      // Update the state with the fetched data
      setProductionRecordRows(data)
    } catch (error) {
      showNotification('Error fetching data', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchProductionRecords()
  }, [selectedDate])

  const filterByShift = (shift) => {
    return productionRecordRows.filter((row) => row.shift === shift)
  }

  return (
    <StyledPageContainer>
      {productionRecordRows.length > 0 ? (
        <>
          <Heading>Achievers</Heading>

          <OperatorTablesWrapper>
            <AchieversTable
              achieversData={filterByShift(1)}
              tableTitle="Shift 1"
              isAchiever={true}
            />

            <AchieversTable
              achieversData={filterByShift(2)}
              tableTitle="Shift 2"
              isAchiever={true}
            />
          </OperatorTablesWrapper>
          <Heading>Non-Achievers</Heading>
          <OperatorTablesWrapper>
            <AchieversTable
              achieversData={filterByShift(1)}
              tableTitle="Shift 1"
              isAchiever={false}
            />
            <AchieversTable
              achieversData={filterByShift(2)}
              tableTitle="Shift 2"
              isAchiever={false}
            />
          </OperatorTablesWrapper>
        </>
      ) : (
        <StyledTableContainer>
          {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}

          <StyledEmptyHeader>There are no operators data</StyledEmptyHeader>
        </StyledTableContainer>
      )}
    </StyledPageContainer>
  )
}

export default OperatorPerformanceDayWise
