import { useAuth0 } from '@auth0/auth0-react'
import { CssBaseline } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ThemeProvider } from '@mui/material/styles'
import * as React from 'react'
import { Route, Routes } from 'react-router-dom'

//context
import ToastContext from './context/ToastContext'

//Layout
import AdminLayout from './layouts/AdminLayout'
import DashboardLayout from './layouts/DashboardLayout'
import EntryLayout from './layouts/EntryLayout'

//Dashboard
import Dashboard from './pages/Dashboard/Dashboard'

//pages
import FilterConfig from './pages/FilterData/FilterConfig'
import HistoricalDashboard from './pages/HistoricalData/DashboardOutlet'
import LiveData from './pages/LiveData/LiveData'
import MachineWiseData from './pages/MachineWiseData/MachineWiseData'
import ManageData from './pages/ManageData'
import ShiftConfig from './pages/ShiftConfig/ShiftConfig'
import ToolLife from './pages/ToolLife/ToolLife'
import Updates from './pages/Updates'
import OperatorInfos from './pages/OperatorInfo/DashboardOutlet'

import MaintenanceData from './pages/Maintenance/MaintenanceOutlet'
import NotFound from './components/NotFound/NotFound'

//styles and assets
import './App.css'
import inputGlobalStyles from './theme/GlobalStyles'
import { lightTheme } from './theme/theme'

const App = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0()

  if (isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px'
        }}
      >
        <CircularProgress />
        <p>Distinct productivity solutions</p>
      </div>
    )
  }

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (!isLoading && !error && !isAuthenticated) {
    loginWithRedirect()
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <ToastContext>
        <CssBaseline />
        {/* Note: It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering. 
        This will ensure that the <style> tag generated would not recalculate on each render. */}
        {inputGlobalStyles}
        <Routes>
          {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
          <Route path="" element={<EntryLayout />} />
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="live-data" element={<LiveData />} />
            <Route path="machine-wise" element={<MachineWiseData />} />
            <Route path="tool-life" element={<ToolLife />} />
            <Route path="historical" element={<HistoricalDashboard />} />
            <Route path="machine-maintenance" element={<MaintenanceData />} />
            <Route path="operator-info" element={<OperatorInfos />} />
          </Route>
          <Route path="admin" element={<AdminLayout />}>
            <Route path="" element={<ManageData />} />
            <Route path="shift-config" element={<ShiftConfig />} />
            <Route path="filter-config" element={<FilterConfig />} />
            <Route path="hmi-update" element={<Updates />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ToastContext>
    </ThemeProvider>
  )
}

export default App
