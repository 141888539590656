import { Button } from '@mui/material'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

//components
import FormTextField from '../../../components/FormTextField/FormTextField'
import FilterConfigComp from './FilterConfigComp'

//utils
import { showNotification } from '../../../utils/toast.utils'

//service
import filterConfigService from '../../../service/filterConfig.service'

const filterConfigSchema = yup.object().shape({
  name: yup.string().required('Filter name is required'),
  filterData: yup.array().of(
    yup.object().shape({
      mttr: yup.number().min(0).max(300).required(),
      mtbf: yup.number().min(0).max(300).required()
    })
  ),
  isActive: yup.boolean()
})

const CreateFilterConfig = () => {
  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const id = params.get('id')

  //component state
  const [name, setName] = useState('')
  const [numberOfFilters, setNumberOfFilters] = useState(1)
  const [filterData, setFilterData] = useState([
    {
      id: nanoid(),
      mttr: 0,
      mtbf: 0
    }
  ])
  const [isLoading, setIsLoading] = useState(false)

  const addFilter = () => {
    if (numberOfFilters >= 10) {
      showNotification('Cannot have more than 10 filters', 'info')
      return
    }
    setFilterData((prev) => [
      ...prev,
      {
        id: nanoid(),
        filter: prev.length + 1,
        mttr: 0,
        mtbf: 0
      }
    ])
    setNumberOfFilters((prev) => prev + 1)
  }

  const removeFilter = (id) => {
    setFilterData((prev) => {
      const newData = prev.filter((data) => data.id !== id)
      return newData.map((data, index) => ({ ...data, filter: index + 1 }))
    })
    setNumberOfFilters((prev) => prev - 1)
  }

  const updateValue = (id, key, value) => {
    setFilterData((prev) => {
      const newData = prev.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            [key]: value
          }
        }
        return data
      })

      return newData
    })
  }

  const saveFilterConfig = async () => {
    console.log('Starting saveFilterConfig')
    console.log('Filter Data: ', filterData)

    // Clean filterData to remove unexpected fields
    const cleanedFilterData = filterData.map(({ id, ...rest }) => rest)

    console.log('Cleaned Filter Data: ', cleanedFilterData)

    const invalidFilter = cleanedFilterData.find((filter) => {
      return filter.mttr < 0 || filter.mtbf < 0
    })

    if (invalidFilter) {
      console.log('Invalid filter found: ', invalidFilter)
      showNotification('Enter proper filter values', 'error')
      return
    }

    const data = {
      name: name,
      numberOfFilters: numberOfFilters,
      filterData: cleanedFilterData
    }

    try {
      console.log('Data before validation: ', data)
      await filterConfigSchema.validate(data)
      console.log('Data validated successfully')
    } catch (error) {
      console.log('Validation error: ', error)
      showNotification(error.message, 'error')
      return
    }

    try {
      setIsLoading(true)
      let response
      if (id) {
        response = await filterConfigService.updateFilterConfig(id, data)
      } else {
        response = await filterConfigService.addFilterConfig(data)
      }

      console.log('Response: ', response)
      if (response.data.success) {
        showNotification(`Filter config saved successfully`, 'success')
        navigate(`/admin/filter-config?tab=config-list`)
      } else {
        showNotification(response.data.message, 'error')
      }
    } catch (err) {
      console.log('Error during API call: ', err)
      showNotification(
        'An error occurred while saving the filter config',
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getFilterConfig = async (id) => {
      try {
        setIsLoading(true)
        const response = await filterConfigService.getFilterConfigById(id)

        if (response.data.success) {
          const data = response.data.data

          setName(data.name)
          setNumberOfFilters(data.numberOfFilters)
          setFilterData(
            data.filterData.map((data) => ({
              ...data,
              id: nanoid()
            }))
          )
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log('Error during fetching filter config: ', err)
      } finally {
        setIsLoading(false)
      }
    }
    if (id) {
      getFilterConfig(id)
    }
  }, [id])

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <div style={{ width: '100%', display: 'flex', flex: 1, gap: '16px' }}>
        <FormTextField
          enableMargin={true}
          autoFocus={true}
          label="Filter config name"
          placeholderText="Enter filter config name"
          textValue={name}
          inputType="text"
          isRequired={true}
          onChangeHandler={(e) => setName(e.target.value)}
        />
      </div>
      {React.Children.toArray(
        filterData.map((data) => (
          <FilterConfigComp
            data={data}
            removeFilter={removeFilter}
            updateValue={updateValue}
          />
        ))
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          {/* <Button variant="outlined" color="primary" onClick={addFilter}>
            Add filter
          </Button> */}
          <Button
            disabled={filterData.length === 0 || !name}
            variant="outlined"
            color="primary"
            onClick={saveFilterConfig}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateFilterConfig
