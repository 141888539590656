import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { uptimeService } from '../../service/uptime.service.js'
import { MACHINES } from '../../utils/constant.utils'
import { showNotification } from '../../utils/toast.utils'

//components
import StyledTable from '../../components/ReactTable/StyledTable.js'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader.js'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField.js'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer.js'

const machineOptions = [MACHINES[0]]
const data_sample = [
  {
    startDate: '2023-10-12T00:00:00.000Z',
    startTime: '23:15:00',
    endDate: '2023-10-12T00:00:00.000Z',
    endTime: '06:59:00',
    idleTime: '2538'
  },
  {
    startDate: '2023-10-13T00:00:00.000Z',
    startTime: '07:05:00',
    endDate: '2023-10-13T00:00:00.000Z',
    endTime: '07:12:00',
    idleTime: '2538'
  },
  {
    startDate: '2023-10-16T00:00:00.000Z',
    startTime: '13:43:00',
    endDate: '2023-10-16T00:00:00.000Z',
    endTime: '13:51:00',
    idleTime: '2538'
  },
  {
    startDate: '2023-10-18T00:00:00.000Z',
    startTime: '23:54:00',
    endDate: '2023-10-18T00:00:00.000Z',
    endTime: '00:13:00',
    idleTime: '2538'
  },
  {
    startDate: '2023-10-19T00:00:00.000Z',
    startTime: '04:21:00',
    endDate: '2023-10-19T00:00:00.000Z',
    endTime: '04:24:00',
    idleTime: '2538'
  },
  {
    startDate: '2023-10-20T00:00:00.000Z',
    startTime: '19:03:00',
    endDate: '2023-10-20T00:00:00.000Z',
    endTime: '19:11:00',
    idleTime: '2538'
  },
  {
    startDate: '2023-11-03T00:00:00.000Z',
    startTime: '20:01:00',
    endDate: '2023-11-03T00:00:00.000Z',
    endTime: '20:10:00',
    idleTime: '2538'
  },
  {
    startDate: '2023-12-16T00:00:00.000Z',
    startTime: '01:50:00',
    endDate: '2023-12-16T00:00:00.000Z',
    endTime: '01:57:00',
    idleTime: '2538'
  },
  {
    startDate: '2023-12-26T00:00:00.000Z',
    startTime: '12:10:00',
    endDate: '2023-12-26T00:00:00.000Z',
    endTime: '12:59:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-01-13T00:00:00.000Z',
    startTime: '17:34:00',
    endDate: '2024-01-13T00:00:00.000Z',
    endTime: '17:48:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-01-25T00:00:00.000Z',
    startTime: '00:42:00',
    endDate: '2024-01-25T00:00:00.000Z',
    endTime: '01:29:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-02-02T00:00:00.000Z',
    startTime: '16:29:00',
    endDate: '2024-02-02T00:00:00.000Z',
    endTime: '17:02:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-03-25T00:00:00.000Z',
    startTime: '14:23:00',
    endDate: '2024-03-25T00:00:00.000Z',
    endTime: '15:02:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-04-05T00:00:00.000Z',
    startTime: '09:25:00',
    endDate: '2024-04-05T00:00:00.000Z',
    endTime: '10:02:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-04-25T00:00:00.000Z',
    startTime: '03:24:00',
    endDate: '2024-04-25T00:00:00.000Z',
    endTime: '06:46:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-04-25T00:00:00.000Z',
    startTime: '11:51:00',
    endDate: '2024-04-25T00:00:00.000Z',
    endTime: '18:46:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-04-26T00:00:00.000Z',
    startTime: '07:24:00',
    endDate: '2024-04-26T00:00:00.000Z',
    endTime: '11:29:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-04-30T00:00:00.000Z',
    startTime: '00:05:00',
    endDate: '2024-04-30T00:00:00.000Z',
    endTime: '00:08:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-04-30T00:00:00.000Z',
    startTime: '00:10:00',
    endDate: '2024-04-30T00:00:00.000Z',
    endTime: '00:19:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-18T00:00:00.000Z',
    startTime: '11:29:00',
    endDate: '2024-06-18T00:00:00.000Z',
    endTime: '16:09:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-18T00:00:00.000Z',
    startTime: '16:24:00',
    endDate: '2024-06-18T00:00:00.000Z',
    endTime: '17:59:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-20T00:00:00.000Z',
    startTime: '15:22:00',
    endDate: '2024-06-20T00:00:00.000Z',
    endTime: '16:25:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-20T00:00:00.000Z',
    startTime: '17:00:00',
    endDate: '2024-06-20T00:00:00.000Z',
    endTime: '17:37:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-20T00:00:00.000Z',
    startTime: '17:52:00',
    endDate: '2024-06-20T00:00:00.000Z',
    endTime: '18:54:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-20T00:00:00.000Z',
    startTime: '21:01:00',
    endDate: '2024-06-20T00:00:00.000Z',
    endTime: '23:59:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-25T00:00:00.000Z',
    startTime: '13:58:00',
    endDate: '2024-06-25T00:00:00.000Z',
    endTime: '14:32:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-25T00:00:00.000Z',
    startTime: '14:48:00',
    endDate: '2024-06-25T00:00:00.000Z',
    endTime: '15:01:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-25T00:00:00.000Z',
    startTime: '15:18:00',
    endDate: '2024-06-25T00:00:00.000Z',
    endTime: '15:21:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-25T00:00:00.000Z',
    startTime: '16:03:00',
    endDate: '2024-06-25T00:00:00.000Z',
    endTime: '17:44:00',
    idleTime: '2538'
  },
  {
    startDate: '2024-06-25T00:00:00.000Z',
    startTime: '19:14:00',
    endDate: '2024-06-25T00:00:00.000Z',
    endTime: '19:38:00',
    idleTime: '2538'
  }
]

const Uptime = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const machine = params.get('machine')
  const defaultMachine = machineOptions.find(({ value }) => value === machine)
  const [isLoading, setIsLoading] = useState(false)
  const [uptimeData, setUptimeData] = useState([])

  const [mttr, setMttr] = useState()
  const [mtbf, setMtbf] = useState()

  const [selectedMachine, setSelectedMachine] = useState(
    defaultMachine || machineOptions[0]
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const params = { machine: selectedMachine.value }
        //const response = await uptimeService(params)
        const response = { status: 200 }
        if (response.status === 200) {
          const fetchedData = data_sample
          setUptimeData(fetchedData)

          const formattedData = addFieldsToObjects(fetchedData)
          const { mttr, mtbf } = calculateAvgMetrics(formattedData)
          setMttr(mttr)
          setMtbf(mtbf)
        } else {
          showNotification('error in setting the data')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [selectedMachine])

  const columns = [
    {
      Header: 'Failure start',
      accessor: 'startDateTime'
    },
    {
      Header: 'Failure end',
      accessor: 'endDateTime'
    },
    {
      Header: 'TTR in minutes',
      accessor: 'ttr'
    },
    {
      Header: 'TBF in days',
      accessor: 'tbf'
    }
  ]

  const formatTimeString = (time) => {
    const [hours, minutes] = time.split(':').map(Number)
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
  }

  const formatDateString = (dateString) => {
    return dateString.split('T')[0]
  }

  const calculateTimeDifference = (startDate, startTime, endDate, endTime) => {
    const start = new Date(
      `${formatDateString(startDate)}T${formatTimeString(startTime)}`
    )
    const end = new Date(
      `${formatDateString(endDate)}T${formatTimeString(endTime)}`
    )
    let diffInMinutes = 0
    if ((end - start) / (1000 * 60) > 0) {
      diffInMinutes = (end - start) / (1000 * 60)
    } else {
      diffInMinutes = (start - end) / (1000 * 60)
    }
    return diffInMinutes
  }

  const calculateDateDifferenceInMinutes = (
    startDate,
    startTime,
    endDate,
    endTime
  ) => {
    const start = new Date(
      `${formatDateString(startDate)}T${formatTimeString(startTime)}`
    )
    const end = new Date(
      `${formatDateString(endDate)}T${formatTimeString(endTime)}`
    )
    const diffInMinutes = (end - start) / (1000 * 60 * 60 * 24)
    if (diffInMinutes > 0) return diffInMinutes.toFixed(2)
    else return -diffInMinutes.toFixed(2)
  }

  const addFieldsToObjects = (data) => {
    return data.map((item, index, array) => {
      const ttr = calculateTimeDifference(
        item.startDate,
        item.startTime,
        item.endDate,
        item.endTime
      )
      const tbf =
        index > 0
          ? calculateDateDifferenceInMinutes(
              item.startDate,
              item.startTime,
              array[index - 1].endDate,
              array[index - 1].endTime
            )
          : 0

      return {
        ...item,
        startDate: formatDateString(item.startDate),
        endDate: formatDateString(item.endDate),
        startDateTime: `${formatDateString(item.startDate)} ${formatTimeString(item.startTime)}`,
        endDateTime: `${formatDateString(item.endDate)} ${formatTimeString(item.endTime)}`,
        ttr,
        tbf
      }
    })
  }

  const calculateAvgMetrics = (formattedData) => {
    let totalTtr = 0
    let totalTbf = 0

    formattedData.forEach((item) => {
      totalTtr += parseFloat(item.ttr)
      totalTbf += parseFloat(item.tbf)
    })

    const avgTtr = totalTtr / formattedData.length

    const avgTbf = totalTbf / (formattedData.length - 1)

    return { mttr: avgTtr, mtbf: avgTbf }
  }

  let formattedData = addFieldsToObjects(uptimeData)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '32px',
          padding: '16px'
        }}
      >
        <div style={{ width: '60%', display: 'flex', gap: '50px', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 400 }}>
            MTTR:{' '}
            <span style={{ fontWeight: 600 }}>
              {mttr !== undefined ? mttr.toFixed(2) : 'N/A'} Minutes
            </span>
          </Typography>
          <Typography sx={{ fontWeight: 400 }}>
            MTBF:{' '}
            <span style={{ fontWeight: 600 }}>
              {mtbf !== undefined ? mtbf.toFixed(2) : 'N/A'} Days
            </span>
          </Typography>
        </div>
        <div
          style={{
            width: '30%',
            float: 'right'
          }}
        >
          <StyledSelectField
            label=""
            placeHolderText="Select machine"
            selectedValue={selectedMachine}
            selectOptions={machineOptions}
            onChangeHandler={(value) => setSelectedMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
        </div>
      </Box>
      <StyledTableContainer>
        {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
        {!isLoading && formattedData.length === 0 && (
          <StyledEmptyHeader>
            There are no uptime data available, please contact someone.
          </StyledEmptyHeader>
        )}
        {!isLoading && formattedData.length > 0 && (
          <StyledTable
            pagination={false}
            columns={columns}
            data={formattedData}
            hiddenColumns={[]}
          />
        )}
      </StyledTableContainer>
    </>
  )
}

export default Uptime
