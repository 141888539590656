import { Button } from '@mui/material'
import React from 'react'

import FormTextField from '../../../components/FormTextField/FormTextField'
import StyledTextFieldWithLabel from '../../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

const FilterConfigComp = ({ data, removeFilter, updateValue }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '16px',
        marginTop: '16px',
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '16px',
        border: '1px solid rgb(18 18 19 /30%)'
      }}
    >
      <div style={{ flex: 1 }}>
        <FormTextField
          autoFocus={true}
          label="Filter"
          placeholderText="Enter Filter number.. Eg: 1"
          textValue={data.filter}
          inputType="number"
          isRequired={true}
          isDisabled={true}
          onChangeHandler={(e) =>
            updateValue(data.id, 'filter', e.target.value)
          }
        />
      </div>
      <div style={{ flex: 1 }}>
        <StyledTextFieldWithLabel
          label="MTTR"
          placeHolderText="Enter MTTR value"
          selectedValue={{ value: data.mttr, label: data.mttr }}
          onChangeHandler={(e) => updateValue(data.id, 'mttr', e.target.value)}
          isRequired={true}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
        />
      </div>
      <div style={{ flex: 1 }}>
        <StyledTextFieldWithLabel
          label="MTBF"
          placeHolderText="Enter MTBF value"
          selectedValue={{ value: data.mtbf, label: data.mtbf }}
          onChangeHandler={(e) => updateValue(data.id, 'mtbf', e.target.value)}
          isRequired={true}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
        />
      </div>
      <div style={{ flex: 1 }}></div>

      <Button
        style={{ marginTop: '32px' }}
        variant="outlined"
        onClick={() => removeFilter(data.id)}
      >
        Remove
      </Button>
    </div>
  )
}

export default FilterConfigComp
