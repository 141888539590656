import { styled } from '@mui/material'
import React from 'react'
import { Link, Outlet } from 'react-router-dom'

//components
import MenuItem from './MenuItem'

//assets
import Logo from '../assets/logo.png'
import { SIDE_PANEL_WIDTH } from '../utils/constants'

import CloudSyncIcon from '@mui/icons-material/CloudSync'
import FilterIcon from '@mui/icons-material/FilterAlt'
import HomeIcon from '@mui/icons-material/Home'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

const App = styled('main')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  color: '#424242',
  backgroundColor: '#fff'
}))

const SidePanel = styled('div')(({ theme }) => ({
  width: `${SIDE_PANEL_WIDTH}px`,
  height: '100vh',
  borderRight: '1px solid #dedede'
}))

const SidePanelInner = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px'
}))

const MainContent = styled('div')(({ theme }) => ({
  width: `calc(100% - ${SIDE_PANEL_WIDTH}px)`,
  width: '100%',
  height: '100vh',
  //padding: '16px 16px 0 16px',
  overflowY: 'auto'
}))

const LogoWrapper = styled('img')(({ theme }) => ({
  maxWidth: '100%'
}))

const LogoBox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 16px',
  marginTop: 'auto'
}))

const NavBox = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  marginTop: '12px'
}))

const MENU_ICON_FONT = 'small'
const SidebarData = [
  {
    title: 'Home',
    path: '/',
    renderIcon: () => {
      return <HomeIcon fontSize={MENU_ICON_FONT} />
    }
  },
  {
    title: 'Manage data',
    path: '/admin',
    renderIcon: () => {
      return <ManageAccountsIcon fontSize={MENU_ICON_FONT} />
    }
  },
  {
    title: 'Shift config',
    path: '/admin/shift-config',
    renderIcon: () => {
      return <HourglassEmptyIcon fontSize={MENU_ICON_FONT} />
    }
  },
  {
    title: 'HMI updates',
    path: '/admin/hmi-update',
    renderIcon: () => {
      return <CloudSyncIcon fontSize={MENU_ICON_FONT} />
    }
  },
  {
    title: 'Filter Config',
    path: '/admin/filter-config',
    renderIcon: () => {
      return <FilterIcon fontSize={MENU_ICON_FONT} />
    }
  }
]

const AdminLayout = () => {
  return (
    <App>
      <SidePanel>
        <SidePanelInner>
          <NavBox>
            {SidebarData.map((item, index) => (
              <MenuItem key={index} item={item} />
            ))}
          </NavBox>
          <LogoBox>
            <Link to="/">
              <LogoWrapper src={Logo} alt="company logo" />
            </Link>
          </LogoBox>
        </SidePanelInner>
      </SidePanel>
      <MainContent>
        <Outlet />
      </MainContent>
    </App>
  )
}

export default AdminLayout
