import axios from 'axios'

const BASE_URL = `${process.env.REACT_APP_DATA_LOGGER_BASE_URL}/v1`

const liveDataService = {
  getLiveData: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/daily-report/live-data`, {
        params: params
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getProductionRecords: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/production-records`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getLiveStatus: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/live-status`)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getDailyReportMachineWise: async (params) => {
    console.log(params)
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/machine-wise`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getDailyReportMachineWiseV2: async (params) => {
    console.log(params)
    try {
      const response = await axios.get(
        `${BASE_URL}/production-info/machine-wise`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getDemographyDataDayWise: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/historical/demography-day-wise`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  //Historical
  getDashboardData: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/historical/monthly-data`, {
        params: params
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getDemographyData: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/historical/demography`, {
        params: params
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getIdleTimeData: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/historical/idle-time`, {
        params: params
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getPartWiseMonthly: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/part-wise-monthly`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getEnergyRecords: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/energy-meter-record/energy-meter`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getOperatorsDataDayWise: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/operators-data/operator-data-day-wise`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getOperatorsDataMonthly: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/operators-data/operator-data`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default liveDataService
