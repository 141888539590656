import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DomainVerificationIcon from '@mui/icons-material/DomainVerification'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal'

// Utils
import { showNotification } from '../../../utils/toast.utils'

const breatheAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(350deg); }
`

const StyledDiv = styled.div`
  padding: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
  &::before {
    content: '';
    position: absolute;
    width: 300px;
    height: 550%;
    background-image: ${(props) =>
      props.isActive
        ? 'conic-gradient(lightgreen, lightgreen)'
        : 'conic-gradient(transparent, transparent)'};
    animation: ${breatheAnimation} 4s linear infinite;
  }
`

const ActionButton = styled.button`
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 8px;
`

/**
 * Returns total filter time in minutes
 * @param {Number} start
 * @param {Number} end
 * @returns {Number}
 */

const Detail = ({ label, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        fontWeight: 500
      }}
    >
      <h4
        style={{
          fontWeight: 600
        }}
      >
        {label} :
      </h4>
      <div>{value}</div>
    </div>
  )
}

const FilterCard = ({ filter, mttr, mtbf }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        padding: '8px 16px',
        border: '1px solid gray',
        borderRadius: '8px',
        width: '100%'
      }}
    >
      <h3>Filter {filter}</h3>

      <Detail label="MTTR" value={`${mttr} minutes`} />

      <Detail label="MTBF" value={`${mtbf} minutes`} />
    </div>
  )
}

const FilterConfigCard = ({ data, selectFilterConfig, deleteFilterConfig }) => {
  // Hooks
  const navigate = useNavigate()
  console.log(typeof data.filterData, Array.isArray(data.filterData))
  // Component state
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openSelectModal, setOpenSelectModal] = useState(false)

  const editFilterConfig = (id) => {
    navigate(`/workspace/config?tab=create-config&id=${id}`)
  }

  // Ensure filterData is an array
  const filterDataArray = Array.isArray(data.filterData)
    ? data.filterData[0]
    : data.filterData
  console.log(filterDataArray)
  return (
    <StyledDiv isActive={data.isActive}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '24px',
          backgroundColor: 'white',
          borderRadius: '8px',
          zIndex: 10,
          cursor: 'pointer',
          border: '1px solid rgb(18 18 19 /30%)',
          '&:hover': {
            backgroundColor: 'lightblue'
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Detail label="Filter Name" value={data.name} />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '16px',
            margin: '16px 0'
          }}
        >
          <FilterCard
            filter={data.id}
            mttr={filterDataArray.mttr}
            mtbf={filterDataArray.mtbf}
          />
        </div>
        <div
          style={{
            display: 'flex'
          }}
        >
          <div
            style={{
              display: 'flex',
              columnGap: '16px',
              justifySelf: 'flex-end',
              marginLeft: 'auto'
            }}
          >
            <div
              onClick={() => {
                if (data.isActive) {
                  showNotification('This is already selected', 'info')
                  return
                }
                setOpenSelectModal(true)
              }}
            >
              <DomainVerificationIcon />
            </div>
            {/* <div onClick={() => editFilterConfig(data.id)}>
              <EditOutlinedIcon />
            </div> */}
            <div
              onClick={(e) => {
                e.stopPropagation()
                if (data.isActive) {
                  showNotification(
                    'You Can not delete running configuration',
                    'warn'
                  )
                  return
                }

                setOpenDeleteModal(true)
              }}
            >
              <DeleteOutlinedIcon />
            </div>
          </div>
        </div>
      </div>
      {openSelectModal && (
        <ConfirmationModal
          isModalOpen={openSelectModal}
          description={`You want to select the filter configuration ${data.name}`}
          buttonTitle="Select"
          clickHandler={() => selectFilterConfig(data.id)}
          closeHandler={() => setOpenSelectModal(false)}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`You want to delete the filter configuration: ${data.name}`}
          buttonTitle="Delete"
          clickHandler={() => deleteFilterConfig(data.id)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </StyledDiv>
  )
}

export default FilterConfigCard
