import axios from 'axios'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const filterConfigService = {
  getFilterConfigById: async (id) => {
    try {
      const response = await axiosObj.get(`/filter-config/${id}`)

      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getFilterConfigs: async () => {
    try {
      const response = await axiosObj.get('/filter-config')

      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  addFilterConfig: async (data) => {
    try {
      const response = await axiosObj.post('/filter-config', data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  updateFilterConfig: async (id, data) => {
    try {
      const response = await axiosObj.patch(`/filter-config/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  deleteFilterConfig: async (id, data) => {
    try {
      const response = await axiosObj.delete(`/filter-config/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  selectFilterConfig: async (id, arrayOfIds) => {
    try {
      const response = await axiosObj.patch(`/filter-config/select/${id}`, {
        arrayOfIds
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default filterConfigService
