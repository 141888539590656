import React, { useState, useEffect } from 'react'
import { Stack } from '@mui/material'
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import liveDataService from '../../service/liveData.service'
import { useMonthSelectorContext } from '../../context/MonthSelectorContext'
import { styled } from '@mui/material'
import OperatorDetails from '../../components/OperatorDetails/OperatorDetails'

const OperatorPerformance = () => {
  const { selectedDate } = useMonthSelectorContext()
  const [operatorName, setOperatorName] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [selectedOperatorName, setSelectedOperatorName] = useState({
    label: 'Select operator',
    value: ''
  })

  // Fetch operator data based on the selected date
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await liveDataService.getOperatorsDataMonthly({
          date: selectedDate
        })
        const data = response.data.data
        const uniqueOperatorNames = [
          { label: 'Select operator', value: '' }, // Default option
          ...data
            .map((item) => ({
              label: item.operator,
              value: item.operator
            }))
            .filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i)
        ]
        setData(data)
        setOperatorName(uniqueOperatorNames)
        setSelectedOperatorName(uniqueOperatorNames[0]) // Default to "Select operator"
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [selectedDate])

  // Filter logic
  const searchObj1 = {
    ...(selectedOperatorName?.value && {
      operator: selectedOperatorName.value
    })
  }

  let formattedData = [...data]
  if (selectedOperatorName?.value) {
    formattedData = formattedData.filter((productionData) =>
      Object.keys(searchObj1).every(
        (key) => searchObj1[key] === productionData[key]
      )
    )
  }

  const LogoWrapper = styled('img')(({ theme }) => ({
    maxWidth: '100%'
  }))

  // Define table columns
  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }) => new Date(value).toLocaleDateString('en-GB')
    },
    { Header: 'Shift', accessor: 'shift' },
    { Header: 'Machine', accessor: 'machine' },
    { Header: 'Operator', accessor: 'operator' },
    { Header: 'Operator Id', accessor: 'operatorId' },
    { Header: 'Part name', accessor: 'partName' },
    { Header: 'Shift target', accessor: 'target' },
    { Header: 'Part count', accessor: 'achieved_qty' },
    { Header: 'Idle time', accessor: 'idletime' },
    { Header: 'Rejection count', accessor: 'rejection' },
    { Header: 'Supervisor', accessor: 'supervisor' },
    { Header: 'Line Inspector', accessor: 'lineInspector' }
  ]

  return (
    <>
      {/* Sticky Header */}
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
          padding: '16px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #eeeeee'
        }}
      >
        <StyledSelectField
          label=""
          placeHolderText="Operator name"
          selectedValue={selectedOperatorName}
          selectOptions={operatorName}
          onChangeHandler={(value) => setSelectedOperatorName(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No operator found"
        />
      </Stack>
      {/* Main Content */}
      <Stack
        sx={{
          gap: '16px',
          padding: '16px'
        }}
      >
        {isLoading ? (
          <>Loading...</>
        ) : error ? (
          <>Error: {error.message}</>
        ) : selectedOperatorName?.value ? (
          <StyledTableContainer>
            {formattedData.length > 0 ? (
              <>
                <OperatorDetails operatorData={formattedData} />
                <StyledTable
                  pagination={false}
                  columns={columns}
                  data={formattedData}
                  hiddenColumns={[]}
                />
              </>
            ) : (
              <StyledEmptyHeader>
                There is no data for this operator
              </StyledEmptyHeader>
            )}
          </StyledTableContainer>
        ) : (
          <StyledEmptyHeader>Please select an operator</StyledEmptyHeader>
        )}
      </Stack>
    </>
  )
}

export default OperatorPerformance
